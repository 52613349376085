<template>
  <main>
    <canvas id="ptninas0a2A" class="canvas-h canvas-w"></canvas>
  </main>
</template>

<script>
import Chart from 'chart.js/auto';
import chartData from '../../../../../assets/datasheets/charts/pesoParaLaTallaNinas0a2A.json';

export default {
  props : ['x', 'y'],
  methods : {
    open(){

      const labels = chartData.datapoints;
      const datasets = [];

      chartData.series.forEach(s => {
        datasets.push({
          type: 'line',
          label: s.title,
          data: s.data,
          borderColor: s.color,
          fill: false,
          tension: 0.5,
          borderWidth : 1.5,
        });
      });

      datasets.push({
        type: 'scatter',
        backgroundColor: 'rgba(75, 00, 150,1)',
        borderColor: 'rgba(75, 00, 150,1)',
        label : 'Peso/Talla',
        data: [{
          "x" : this.x,
          "y" : this.y
        }],
        options: {
          scales: {
            x: {
              type: 'linear',
              position: 'bottom'
            }
          },
        },
        elements : {
          point : {
            radius : 4
          }
        }
      });

      const data = {
        labels: labels,
        datasets: datasets
      };

      const config = {
        data: data,
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: 'Peso para la talla en Niñas de 0 a 2 Años'
            },
          },
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Talla'
              },
              ticks: {
                callback: function(value) {
                  if(Number(this.getLabelForValue(value)) % 5 === 0){
                      return `${this.getLabelForValue(value)} cm`;
                  }
                }
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Peso (kg)'
              },
              ticks: {
                callback: function(value) {
                  return `${this.getLabelForValue(value)} kg`;
                }
              },
              suggestedMin : 0,
              suggestedMax : 28
            }
          },
          elements : {
            point : {
              radius : 0
            }
          }
        },
      };

      const ctx = document.getElementById('ptninas0a2A').getContext('2d');

      new Chart(ctx, config);
    },
    export(){
      const canva = document.getElementById('ptninas0a2A');
      const urlData = canva.toDataURL('image/png', 0.8);
      return {
        chart : 'Peso para la talla niñas de 0 a 2 años',
        imageData : urlData,
        observaciones:'',
        codigo:'ptninas0a2A'
      };
    }
  },
  created() {
    this.$nextTick(() => this.open());
  }
}

</script>

<style scoped>
  .canvas-h{
    height: 100%;
  }
  .canvas-w{
    width: 100%;
  }
</style>