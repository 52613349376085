<template>
  <div>
    <canvas id="peninos2a5A" class="canvas-h canvas-w"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import chartData from '../../../../../assets/datasheets/charts/pesoParaLaEdadNinos2a5A.json';

export default {
  props : ['x','y'],
  data () {
    return {
      chart : '',
    }
  },
  methods : {
    open(){
      if(this.chart === '') this.drawChart();
    },
    drawChart(){
      const labels = chartData.datapoints;
      const datasets = [];

      chartData.series.forEach(s => {
        datasets.push({
          type : 'line',
          label: s.title,
          data: s.data,
          borderColor: s.color,
          fill: false,
          tension: 0.5,
          borderWidth : 1.5,
        });
      });

      datasets.push({
        type: 'scatter',
        backgroundColor: 'rgba(75, 00, 150,1)',
        borderColor: 'rgba(75, 00, 150,1)',
        label : 'Peso/Edad',
        data: [{
          "x" : Math.floor(this.x * 30),
          "y" : this.y
        }],
        options: {
          scales: {
            x: {
              type: 'linear',
              position: 'bottom'
            }
          },
        },
        elements : {
          point : {
            radius : 4
          }
        }
      });

      const data = {
        labels: labels,
        datasets: datasets
      };

      const config = {
        data: data,
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: 'Peso para la Edad Niños de 2 a 5 Años'
            },
          },
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Edad En Meses'
              },
              ticks: {
                callback: function(value) {
                  let meses = new Array(40).fill(1).map((v,i) => i+24);
                  if(meses.includes((Number(value) + 729) / 30)){
                    return `${(value + 729) / 30} M`;
                  }
                }
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Peso'
              },
              suggestedMin: 0,
            }
          },
          elements : {
            point : {
              radius : 0
            }
          },
          animation: {
            duration: 0
          }
        },
      };

      const ctx = document.getElementById('peninos2a5A').getContext('2d');

      this.chart = new Chart(ctx, config);
    },
    export(){
      const canva = document.getElementById('peninos2a5A');
      const urlData = canva.toDataURL('image/png', 0.8);
      return {
        chart : 'Peso para la Edad Niños 2 a 5 Años',
        imageData : urlData,
        observaciones:'',
        codigo:'peninos2a5A'
      };
    }
  },
  created() {
    this.$nextTick(() => this.open());
  }
}

</script>

<style scoped>
  .canvas-h{
    height: 100%;
  }
  .canvas-w{
    width: 100%;
  }
</style>